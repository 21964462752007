/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import {createUseStyles} from "react-jss";
import UI from "../UI";

interface HeroMaskProps {
    variant?: "light" | "normal" | "dark";
    opacity?: number;
}

function backgroundImage(variant: HeroMaskProps["variant"]) {
    switch (variant) {
        case "light":
            return `linear-gradient(
                to bottom,
                ${UI.colors.darkGrey.toCSS()},
                ${UI.colors.lightGrey.toCSS()} 43%,
                ${UI.colors.translucentWhite.toCSS()}
            )`;

        case "dark":
            return `linear-gradient(
                to bottom,
                ${UI.colors.darkGrey.toCSS()},
                43%,
                ${UI.colors.translucentWhite.toCSS()}
            )`;

        default:
            return `linear-gradient(
                to bottom,
                ${UI.colors.darkGrey.toCSS()},
                ${UI.colors.grey.toCSS()} 43%,
                ${UI.colors.translucentWhite.toCSS()}
            )`;
    }
}

const useStyles = createUseStyles({
    root: {
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
        backgroundImage: ({variant}: HeroMaskProps) => backgroundImage(variant),
        mixBlendMode: "multiply",
        zIndex: 2,
        opacity: ({opacity}: HeroMaskProps) => opacity
    }
});

function HeroMask(props: HeroMaskProps) {
    const classes = useStyles(props);
    return <div className={classes.root} />;
}

export default memo(HeroMask);
