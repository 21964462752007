/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import UI from "../UI";

const useStyles = createUseStyles({
    root: {
        position: "relative",
        color: UI.colors.white.toCSS(),
        height: 670,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        width: "100%",
        zIndex: 3,

        "& p": {
            fontSize: "2.25rem",
            fontWeight: 400,
            lineHeight: 1.4,
            maxWidth: "75%",
            margin: "auto",
            textAlign: "center",

            "&:lang(ja)": {
                fontSize: "2rem"
            },

            [UI.media.only("xs")]: {
                fontSize: "1.5rem",

                ["&:lang(ja)"]: {
                    fontSize: "1.5rem"
                }
            }
        },

        [UI.media.only("xs")]: {
            height: "auto",
            minHeight: "100vh"
        }
    },
    content: {
        position: "relative",
        zIndex: 5
    }
});

interface HeroContentProps {
    children: ReactNode;
}

function HeroContent(props: HeroContentProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>{props.children}</div>
        </div>
    );
}

export default memo(HeroContent);
