/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import UI from "./UI";
import {H2} from "./typography/Headings";
import {Lead} from "./typography/Text";
import Container from "./Container";

const useStyles = createUseStyles({
    root: {
        padding: "4rem 0",
        [UI.media.only("xs")]: {
            padding: "2rem 0"
        }
    }
});

interface IntroProps {
    title?: string;
    children?: ReactNode;
}

function renderTitle(title?: string) {
    if (!title) {
        return null;
    }

    return <H2>{title}</H2>;
}

function renderContent(children?: ReactNode) {
    if (!children) {
        return null;
    }

    return <Lead size="large">{children}</Lead>;
}

function Intro(props: IntroProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container>
                {renderTitle(props.title)}
                {renderContent(props.children)}
            </Container>
        </div>
    );
}

export default memo(Intro);
