/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import Hero from "../common/hero/Hero";
import HeroBackground from "../common/hero/HeroBackground";
import HeroMask from "../common/hero/HeroMask";
import HeroContent from "../common/hero/HeroContent";
import {createUseStyles} from "react-jss";
import {StaticImage} from "gatsby-plugin-image";

interface CompanyHeroProps {
    children: ReactNode;
}

const useStyles = createUseStyles({
    background: {
        objectPosition: "75% bottom",
        objectFit: "cover",
        width: "100%",
        height: "100%"
    }
});

export function CompanyHero({children}: CompanyHeroProps) {
    const classes = useStyles();

    return (
        <Hero>
            <HeroBackground>
                <StaticImage
                    className={classes.background}
                    src="../assets/raw-photos/team-edit.jpg"
                    alt=""
                    layout="constrained"
                    width={1840}
                    quality={100}
                />
            </HeroBackground>
            <HeroMask opacity={0.44} variant="light" />
            <HeroContent>{children}</HeroContent>
        </Hero>
    );
}

export default memo(CompanyHero);
