/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import UI from "../common/UI";
import {H3, H4} from "../common/typography/Headings";

const useStyles = createUseStyles({
    root: {
        paddingBottom: "3.5rem",
        marginBottom: "3.5rem",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderBottom: `2px solid ${UI.colors.lightGrey.toCSS()}`,
        [UI.media.only("xs")]: {
            flexDirection: "column"
        },
        "&:last-child": {
            paddingBottom: 0,
            border: 0
        },
        "& p": {
            lineHeight: 1.8
        }
    },
    photo: {
        "& img": {
            marginRight: "2rem",
            maxWidth: 210
        },
        [UI.media.only("xs")]: {
            "& img": {
                width: "100%",
                margin: "0 auto 1rem",
                maxWidth: 400
            }
        }
    },
    name: {
        marginBottom: 0,
        fontSize: "1.375rem",
        fontWeight: "bold"
    },
    title: {
        fontSize: "1.375rem",
        fontWeight: "bold"
    }
});

interface CompanyMemberProps {
    title: string;
    name: string;
    photo: string;
    children: ReactNode;
}

function CompanyMember(props: CompanyMemberProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.photo}>
                <img src={props.photo} alt={props.name} />
            </div>
            <div>
                <H3 className={classes.name}>{props.name}</H3>
                <H4 className={classes.title}>{props.title}</H4>
                {props.children}
            </div>
        </div>
    );
}

export default memo(CompanyMember);
