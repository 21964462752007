/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import Navigation from "../Navigation";

const useStyles = createUseStyles({
    root: {
        position: "relative"
    }
});

interface HeroProps {
    children: ReactNode;
}

function Hero({children}: HeroProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Navigation />
            {children}
        </div>
    );
}

export default memo(Hero);
