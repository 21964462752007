/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import UI from "../common/UI";
import Container from "../common/Container";
import {H2} from "../common/typography/Headings";
import {Lead} from "../common/typography/Text";
import {StaticImage} from "gatsby-plugin-image";

const useStyles = createUseStyles({
    root: {
        position: "relative"
    },
    mask: {
        backgroundImage: `linear-gradient(
            to top,
            ${UI.colors.darkGrey.toCSS()},
            ${UI.colors.lightGrey.toCSS()} 60%,
            ${UI.colors.translucentWhite.toCSS()}
        )`,
        height: "100%",
        left: 0,
        mixBlendMode: "multiply",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: 2
    },
    content: {
        color: UI.colors.white.toCSS(),
        padding: "4rem 0",
        position: "relative",
        zIndex: 3,
        [UI.media.only("xs")]: {
            padding: "2rem 0"
        }
    },
    backgroundImage: {
        height: "100%",
        objectFit: "cover",
        left: 0,
        top: 0,
        position: "absolute",
        opacity: 0.57
    }
});

interface JoinUsProps {
    title: string;
    children: ReactNode;
}

function CompanyJoinUs(props: JoinUsProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <StaticImage
                className={classes.backgroundImage}
                src="../assets/raw-photos/fuji.jpg"
                alt=""
                layout="constrained"
                width={1500}
            />
            <div className={classes.mask} />
            <div className={classes.content}>
                <Container>
                    <H2>{props.title}</H2>
                    <Lead size="large">{props.children}</Lead>
                </Container>
            </div>
        </div>
    );
}

export default memo(CompanyJoinUs);
