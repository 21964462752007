/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import Container from "../common/Container";
import {H2} from "../common/typography/Headings";

interface TeamProps {
    title: string;
    children: ReactNode;
}

function CompanyTeam({title, children}: TeamProps) {
    return (
        <div>
            <Container>
                <H2>{title}</H2>
                {children}
            </Container>
        </div>
    );
}

export default memo(CompanyTeam);
