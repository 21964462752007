/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import Button from "../../common/Button";
import Footer from "../../common/Footer";
import Intro from "../../common/Intro";
import Page from "../../common/Page";
import Meta from "../../common/Meta";
import CompanyHero from "../../company/CompanyHero";
import JoinUs from "../../company/CompanyJoinUs";
import TeamMember from "../../company/CompanyMember";
import Team from "../../company/CompanyTeam";
import {H1} from "../../common/typography/Headings";
import ChristianPhoto from "../../assets/images/team/christian.jpg";
import ErlingPhoto from "../../assets/images/team/erling.jpg";
import GautePhoto from "../../assets/images/team/gaute.jpg";
import JaredPhoto from "../../assets/images/team/jared.jpg";

const CompanyEn = () => (
    <Page lang="en" title="Company" path="/en/company/">
        <Meta name="description">
            We're a team of experienced software engineers in Tokyo and Oslo using the latest
            advances in natural language processing, machine learning, and AI to help businesses all
            over the world solve difficult problems.
        </Meta>
        <CompanyHero>
            <H1>Company</H1>
        </CompanyHero>

        <Intro title="Who we are">
            <p>
                We’re a team of experienced software engineers in Tokyo and Oslo using the latest
                advances in natural language processing, machine learning, and AI to help businesses
                all over the world solve difficult problems.
            </p>
        </Intro>

        <Team title="The team">
            <TeamMember name="Christian Moen" title="CEO" photo={ChristianPhoto}>
                <p>
                    Christian founded Atilika to help Japanese companies apply search and related
                    technologies to improve their businesses. He has 17 years of experience working
                    with most aspects of search; from search engine R&amp;D to deploying large,
                    complex and mission-critical search solutions to leading businesses.
                </p>

                <p>
                    Christian is also an Apache Lucene and Apache Solr committer and he helps
                    maintain overall Japanese language support.
                </p>

                <p>
                    Prior to starting Atilika, Christian spent 10 years at FAST (now Microsoft).
                    Christian holds an MSc in computer science from the University of Oslo, Norway.
                </p>
            </TeamMember>

            <TeamMember
                name="Gaute Lambertsen"
                title="Partner &amp; General Manager Atilika Norway"
                photo={GautePhoto}
            >
                <p>
                    Gaute joined Atilika in April 2012. Gaute has experience from many aspects in
                    software and computing prior to joining Atilika, from research on mobile
                    communication networks at Ritsumeikan University, ubiquitous computing at PRESTO
                    and Nokia Research Center, to development of consumer electronics products at
                    Sony Digital Network Applications.
                </p>

                <p>Gaute holds an MSc in computer science from Ritsumeikan University, Japan.</p>
            </TeamMember>

            <TeamMember name="Jared Lewis" title="Partner" photo={JaredPhoto}>
                <p>
                    Jared joined Atilika as partner in 2017 and has lived in Tokyo since 2011. He
                    has over six years of professional software development experience and holds a
                    BS from Northwestern University in the United States.
                </p>
            </TeamMember>

            <TeamMember
                name="Erling Wishman Eeg-Henriksen"
                title="Software Engineer"
                photo={ErlingPhoto}
            >
                <p>
                    Erling joined Atilika in 2016 after one year as an intern in the Tokyo office.
                    He has a MSc in Computer Science from NTNU in Norway and spent a year as an
                    exchange student at the Tokyo Institute of Technology.
                </p>
            </TeamMember>
        </Team>
        <JoinUs title="Join us">
            <p>
                At Atilika, we are always looking for talented engineers to join our teams in Tokyo
                and Oslo. Atilika offers a fun, collaborative work environment, competitive salary,
                visa sponsorship, and more.
            </p>

            <p>
                <Button to="/en/careers/">Learn More</Button>
            </p>
        </JoinUs>
        <Footer lang="en" />
    </Page>
);

export default memo(CompanyEn);
