/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactElement} from "react";
import {createUseStyles} from "react-jss";
import {IStaticImageProps} from "gatsby-plugin-image/dist/src/components/static-image.server";

interface HeroBackgroundProps {
    children: ReactElement<IStaticImageProps>;
    opacity?: number;
}

const useStyles = createUseStyles({
    root: {
        height: 670,
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%"
    }
});

function HeroBackground({opacity, children}: HeroBackgroundProps) {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{opacity}}>
            {children}
        </div>
    );
}

export default memo(HeroBackground);
